import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Members from "../components/Members"
import UnlockContext from "../contexts/UnlockContext"

const BlogPostBody = ({ post }) => {
  const { isMember, address, becomeMember, signIn } = React.useContext(UnlockContext)

  // can we split in a better place?
  let preview = post.frontmatter.description || post.excerpt
  let content = post.html
  let contentWithPreview = content.split('<p>==LOCKED==</p>')
  if (contentWithPreview[1]) {
    content = contentWithPreview[1]
    preview = contentWithPreview[0]
  }

  const getMembership = (<p>
    The rest of this post is for members only!<br></br> Purchase a NFT
    subscription in just a few seconds for <strong>$0.5 per month</strong> ($1 if you pay by card...).{" "}
    <Link to="/membership">Learn more...</Link>
  </p>)

  if (post.frontmatter.draft) {
    return <p>This post is not published yet... please come back soon!</p>
  }

  if (post.frontmatter.unlocked || isMember === "yes") {
    return (
      <>
        <section
          dangerouslySetInnerHTML={{ __html: content }}
          itemProp="articleBody"
        />
        <hr />
        <div>This post was made possible by support from our members including:</div>
        <Members limit={10} />
        <hr />
        <footer>
          <Bio />
        </footer>
      </>
    )
  } else if (!address) {
    return (
      <>
        <section
          dangerouslySetInnerHTML={{ __html: preview }}
          itemProp="articleBody"
        />
        <div className="unlock-protocol-cta">
          <button onClick={signIn}>Sign-in</button>
          {getMembership}
        </div>
      </>
    )
  } else if (isMember === "no") {
    return (
      <>
        <section
          dangerouslySetInnerHTML={{ __html: preview }}
          itemProp="articleBody"
        />
        <div className="unlock-protocol-cta">
          <button onClick={becomeMember}>Become a Member</button>
          {getMembership}
        </div>
      </>
    )
  } else {
    // loading
    return null
  }
}

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  const locks = {}
  data.site.siteMetadata.locks.forEach(lock => {
    locks[lock.address] = {
      network: lock.network,
    }
  })

  return (
    <Layout locks={locks} location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <BlogPostBody post={post} />
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        locks {
          network
          address
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 240)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        draft
        unlocked
        locks {
          network
          address
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
