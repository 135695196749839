import * as React from "react"


const lockMembersQuery = lock => `{
  lock(id: ${JSON.stringify(lock)}) {
    keys {
      owner 
      tokenId
      expiration
    }
  }
}`

const lock = '0xba6beb73cdaec34957290cb7e3522187f8382b55'


const Members = ({ limit }) => {
  const [members, setMembers] = React.useState([])

  React.useEffect(() => {
    const listMembers = async () => {
      const graphUrl = "https://api.studio.thegraph.com/query/65299/unlock-protocol-base/version/latest"
      const response = await fetch(
        graphUrl,
        {
          method: "POST",
          body: JSON.stringify({ query: lockMembersQuery(lock) })
        }
      ).then(res => res.json())

      const addresses = response.data.lock.keys.map((key) => key.owner).sort(() => (Math.random() > .5) ? 1 : -1)
      if (limit) {
        setMembers(addresses.slice(0, limit))
      } else {
        setMembers(addresses)
      }
    }
    listMembers()

  }, [limit])


  return (
    <div className="gallery">
      {members.map((address, idx) =>
        <a key={idx} rel="noreferrer" target="_blank" href={`https://etherscan.io/address/${address}`}><img alt="a member" src={`https://effigy.im/a/${address}.svg`} /></a>
      )}
    </div >
  )
}


export default Members
